import React from 'react';
import Mission from './Mission/Mission';
import Team from './Team/Team';
import { ScrollToTop } from '../../components/utils/Utils.jsx';


export default function About() {
    return (
        <div>
            <ScrollToTop/>
            <div className='min-height-screen'>
                <Mission/>
                <Team/>
            </div>
        </div>
    )
}
