import React from 'react';
import vasculature from '../../assets/vasculature.jpeg';
import { Link } from 'react-router-dom';
import wallpaper from '../../assets/organs-wallpaper.jpeg';


export default function Hero(props) {
  return (
    <div className={props.className}>
      <main>
        <div>
          {/* Hero card */}
          <div className="relative">

            {/* Background Wallpaper */}
            <div 
            className="absolute inset-x-0 bottom-0 md:-mb-8 h-1/2 bg-gray-100 border-t border-b border-red-300 opacity-50"
            style={{ backgroundImage:`url(${wallpaper})` }}
            />

            <div className="max-w-7xl mx-auto sm:p-6 md:px-6 xl:p-0">
              <div className="relative sm:rounded-2xl sm:overflow-hidden shadow-lg md:border-4 md:border-gray-200">
                <div className="absolute inset-0">
                  <img
                    className="h-full w-full object-cover"
                    src={vasculature}
                    alt="vasculature"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-60" />
                </div>
                <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                  <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                    <span className="block text-white">We Grow Vasculature</span>
                  </h1>
                  <p className="mt-6 max-w-lg mx-auto text-center text-xl text-gray-300 sm:max-w-3xl">
                    Vasogen's breakthrough <b>Vascular Generation Technology</b>™ enables the growth of large-scale tissues, organs, synthetic meats and cell therapies of all kinds. Welcome to the future.
                  </p>

                        
                  <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                    <div className="space-y-4 sm:space-y-0 sm:mx-auto">
                      <Link
                        to="/contact"
                        className="flex flex-1 items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                      >
                        Get in touch
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </main>
    </div>
  )
}

