import React from 'react';
import { ScrollToTop } from '../../components/utils/Utils';
import Hero from './Hero';

import scientist from './assets/scientist.png';
import aneurysm from './assets/aneurysm.png';
import heart from './assets/heart.png';
import meat from './assets/meat.png';
import people from './assets/people.png';

export default function Home() {
    return (
        <div>
            <ScrollToTop/>
            <div>
                <Hero className='pt-0 lg:pt-12'/>
                <TechnologyAreas className='mt-12'/>
            </div>
        </div>
    )
}

const applications = [
    { 
        label: "Basic Science and Biopharma R&D", 
        content: "Vasogen’s easy-to-use Vascular Generation Platform enables academic and industrial researchers to conduct novel experiments exploring the biology of vascular networks, and vascularized tissues, organs, and other assembloid biosystems",
        path: scientist
    },
    { 
        label: "Vessels for Aneurysm Repair",
        content: "Vasogen's generated autologous vascular tissue segments provide a new, sustainable, on-demand source for vasculature perfectly suited for use in aneurysm repair and other life-saving surgical procedures",
        path: aneurysm,
    },
    { 
        label: "Vascularized Tissues and Organs",
        content:"Our Vascular Generation Technology enables the cultivation of large-scale vascularized tissues and organ systems",
        path: heart
    },
    { 
        label: "Large-Scale Synthetic Meats",
        content: "The ability to vascularize tissues enables the industrial-scale cultivation of synthetic meats",
        path: meat
    },
    { 
        label: "Novel Cell Therapy Development",
        content: "Vasogen’s Vascular Generation Technology enables the cultivation of novel assembloids and the development of a wide variety of potential cell therapies",
        path: people
    },

]



const TechnologyAreas = (props) => {
    return (
        <div 
        className={`flex flex-col w-full md:max-w-7xl px-4 md:pt-12 md:px-0 mx-auto ${props.className}`}
        >


            <div className='flex flex-col lg:flex-row lg:space-x-8 text-center lg:text-left mb-6 md:mb-8 lg:mb-20 lg:ml-16'>
                <div className='font-extrabold text-3xl md:text-6xl text-red-500 flex flew-row space-x-2 md:space-x-4 lg:flex-col lg:space-x-0 lg:space-y-4 mx-auto mb-4 md:pl-8 lg:p-0 lg:mb-0 lg:m-0'>
                    <div>Technology</div>
                    <div>Applications</div>
                </div>
                <div className='font-regular text-base md:text-xl text-red-400 md:flex-1 mx-4 md:mt-4 lg:pr-60 xl:pr-80'> 
                    The ability to grow vascular networks unlocks entire new worlds of potential academic, industrial, and therapeutic applications
                </div>
            </div>


            <div className='flex flex-col w-full md:w-3/4 py-6 px-2 md:p-0 md:ml-8 md:mt-6 lg:ml-16 lg:mt-0'>
            { applications.map((app, i) => {
                return(
                  <ListItem label={app.label} content={app.content} path={app.path} key={i} className='mb-16'/>
                )
            })}
            </div>

        </div>
    )
}

const ListItem = (props) => {
    return (
        <div className={`flex flex-col md:flex-row md:space-x-8 lg:space-x-12 mx-auto md:mx-0 ${props.className}`}>

            <img
            className='w-80 h-80 mb-6 lg:mb-0 md:w-60 md:h-60 mx-auto'
            src={props.path}
            alt={props.label}
            />

            <div className='flex-1 md:mt-8'>
                <div className='font-bold text-xl md text-xl lg:text-3xl text-gray-800 mb-2 lg:mb-4 text-center md:text-left'>
                    {props.label}
                </div>
                <div className='font-base text-base md:text-lg text-gray-600 text-center md:text-left'>
                    {props.content}
                </div>
            </div>

        </div>
    )
}

