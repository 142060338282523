import React from 'react';
import { ScrollToTop } from '../../components/utils/Utils.jsx';
import { BasicMessageForm } from '../../forms/BasicForm';


export default function Contact() {
    return (
        <div>
            <ScrollToTop/>
            <div className='flex flex-row min-h-screen'>
                <div className='flex w-full'>
                    <div className='w-full mx-auto md:p-20 px-4'>
	                    <div className='text-red-400 text-4xl md:text-5xl font-extrabold my-4 md:mb-8 md:mt-0'>👋 Hi! What's up?</div>
	                    <div className='text-red-400 text-lg md:text-xl font-normal my-4 md:mb-8 md:mt-0'>
                            Want to use our technology or partner to help build a better future? We'd love to hear from you!
                        </div>
                        <BasicMessageForm />
                    </div>
                </div>
	        </div>
        </div>
    )
}
