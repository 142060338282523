// import logo from './assets/logo.svg';
// Lettering;
// ('celltonomy');
// font: syncopate;

import React from 'react';
import '../../styles/index.css';
import './App.css';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { BasicMessageForm } from '../../forms/BasicForm';
// import { BasicMessagePanel } from '../BasicMessagePanel';
import Superbar from '../Superbar/Superbar'
// import SideMenu from '../SideMenu/SideMenu'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    // Link,
} from 'react-router-dom';

import Home from '../../pages/Home/Home';
import About from '../../pages/About/About';
import Contact from '../../pages/Contact/Contact';
import Footer from '../Footer/Footer.jsx';


export const queryClient = new QueryClient();


function App() {
  return (
    <QueryClientProvider client={queryClient}>

      <Helmet>
        <title>Vasogen.Bio | Vascular Generation Technology</title>
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css"/>
      </Helmet>

      <div className='min-h-screen bg-white overflow-hidden'>
        <Router>
            <Superbar/>
            <Switch>
                <Route path='/about'>
                    <About/>
                </Route>
                <Route path='/contact'>
                    <Contact/>
                </Route>
                <Route path='/'>
                    <Home/>
                </Route>
            </Switch>
            <Footer/>
        </Router>
      </div>

    </QueryClientProvider>
  );
}



// const Contact = () => {
//     return (
//         <div className='flex flex-row min-h-screen'>
//           <div className='flex w-full'>
//             <div className='w-full mx-auto md:p-20 px-4'>
// 	            <div className='text-red-400 text-4xl md:text-5xl font-extrabold my-4 md:mb-8 md:mt-0'>👋 Hi! What's up?</div>
// 	            <div className='text-red-400 text-lg md:text-xl font-normal my-4 md:mb-8 md:mt-0'>
//                     Want to use our technology or partner to help build a better future? We'd love to hear from you!
//                 </div>
//                 <BasicMessageForm />
//             </div>
//           </div>
// 	    </div>
//     )
// }

// const Home = () => {
//     return (
//         <>
//             <h1>Vasogen</h1>
//         </>
//     )
// }



export default App;
