import React from 'react';
import vessel from './assets/vessel.jpeg';
// import Team from '../Team/Team';
// import { ScrollToTop } from '../../../components/utils/Utils.jsx';

export default function Mission(props) {
    return (
        <div className={`flex flex-col xl:flex-row ${props.className}`}>
            <div className='order-2 mx-auto xl:order-first'>
                <BackgroundPattern/>
                <BackgroundImage/>
            </div>
            <MissionStatement/>
        </div>
    )
}


const BackgroundPattern = () => {
    return (
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
            <div className="relative sm:py-16 lg:py-0">
                <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                    <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
                    <svg
                    className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                    width={404}
                    height={392}
                    fill="none"
                    viewBox="0 0 404 392"
                    >
                        <defs>
                            <pattern
                            id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                    </svg>
                </div>
            </div>
        </div>
    )
}


const BackgroundImage = () => {
    return (
        <div className="relative mx-auto px-4 max-w-3xl sm:px-6 lg:px-0 lg:mx-8 lg:py-20">
            <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src={vessel}
                    alt="vessel"
                    />
                    <div className="absolute inset-0 bg-rose-500 mix-blend-multiply" />
                    <div className="absolute inset-0 bg-gradient-to-t from-rose-600 via-rose-600 opacity-90" />
                    <div className="relative px-8">
                        <blockquote className="mt-8">
                            <div className="relative text-lg font-medium text-white md:flex-grow">
                                <svg
                                className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-300 opacity-70"
                                fill="currentColor"
                                viewBox="0 0 32 32"
                                aria-hidden="true"
                                >
                                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                </svg>
                                <p className="relative">
                                    This is the first macroscopic vessel we grew, which we were actually able to grow on the very first run of our new <b>Vascular Generation Technology</b> platform.
                                    There's a lot more to do, but through our work making vascularization technology a reality, <b>Vasogen</b> is enabling entire new worlds of biotechnology products and therapies to help extend and enhance life.
                                </p>
                            </div>
                            <footer className="mt-4">
                                <p className="text-base font-semibold text-red-200">Ethan Stillman, CEO at Vasogen.Bio</p>
                            </footer>
                        </blockquote>
                    </div>
                </div>
            </div>

    )
}


const MissionStatement= () => {
    return (
        <div className="relative mx-auto max-w-full px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <div className="pt-8 lg:pt-20">
                <h2 className="text-3xl text-red-500 font-extrabold tracking-tight sm:text-4xl">
                    Our mission is to radically extend & enhance human lifespans
                </h2>
                <div className="mt-6 text-gray-500 space-y-6 mb-6 lg:mb-0">
                    <p className="text-lg mb-6">
                        Vasogen's breakthrough <b>Vascular Generation Technology</b>™ enables the cultivation of large-scale tissues, organs, and cell therapies of all kinds, as well as synthetic meats, and the development of a wide assortment of new biopharmaceutical development platforms.
                    </p>
                    <p className='text-lg hidden lg:block'>
                        The ability to generate vasculature, and by extension grow large-scale bio-systems, fundamentally advances the state-of-the-art in regenerative medicine and biotechnology.
                    </p>
                    <p className='text-lg'>
                        Vasogen's work to perfect vascularization technology and help realize the vast potential this technology enables is driven by our commitment to expand and enhance human lifespans, and create a healthier more vibrant world.
                    </p>
                </div>
            </div>
        </div>
    )
}

